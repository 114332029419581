.container-forget {
  display: grid;
  justify-content: center;
}
.container-forget > form,
.container #home-video-main {
  border-radius: 0px 60px;
  border: 3px dashed var(--text-primary-800);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 50px 100px 0 rgba(0, 75, 117, 0.3);
  backdrop-filter: blur(20px);
  padding: 37px 37px;
  margin: 50px 0px 80px 0px;
  /* display: grid;
  justify-content: center;
  margin: 220px 0px 80px 0px;
  padding: 0px 45px;
  border: 0.5px solid;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 50px;
  flex-direction: column;
  box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
    rgba(36, 160, 218, 0.333) 0px 15px 30px;
  border: 1px solid #249fda; */
}
.field-forget {
  display: grid;
  position: relative;
  margin-top: 5px;
  text-align: center;
  width: 380px;
}
.field-forget input {
  display: block;
  width: 98%;
  padding: 14px 0 14px 43px;
  margin: 5px 0;
  border-radius: 20px;
  border: 1px solid #3ca899;
  box-sizing: border-box;
  background-color: rgba(237, 239, 241, 0.932);
}
button {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 5px auto;
  border-radius: 30px;
  border-style: none;
  cursor: pointer;
}
button a {
  text-decoration: none;
  color: rgb(253, 253, 253);
  font-size: 20px;
}
.uname-forget {
  width: 22px;
  top: 20px;
  position: absolute;
  left: 10px;
}

.container-forget h1 {
  color: var(--radium-info-600);
  text-align: center;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 23px;
}
.btn1-forget {
  background-color: var(--text-primary-800);;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin: 5px auto;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: all 0.2s;
  padding: 12px 17px;
  align-items: center;
}
.btn1-forget:hover {
  background-color: var(--text-primary-800);;
  transition: all 0.2s;
}

.container-forget p {
  text-align: left;
  padding-left: 0px;
  font-size: 12px;
  color: rgb(218, 31, 31);
}

.field-forget label {
  justify-self: left;
  font-weight: 500;
  padding-left: -10px;
}

@media screen and (max-width: 500px) {
  .container-forget {
    display: block;
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .container-forget h1 {
    margin-top: 1px;
    font-size: 25px;
  }
  .field-forget {
    justify-content: center;
    margin-top: 1px;
  }
  .field-forget input {
    width: 270px;
  }

  .field-forget img {
    position: absolute;
    padding-top: 38px;
    padding-left: 68px;
    width: 18px;
    height: 18px;
  }

  button a {
    font-size: small;
  }
  .btn1-forget {
    width: 195px;
  }

  .container-forget p {
    text-align: center;
  }
  .container #home-video-main {
    padding: 1px 15px 20px !important;
  }
}
@media screen and (max-width: 320px) {
  .container-forget {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .field-forget {
    justify-content: center;
  }
  .field-forget input {
    width: 220px;
  }

  .field-forget img {
    position: absolute;
    padding-top: 38px;
    padding-left: 92px;
    width: 18px;
    height: 18px;
  }

  button a {
    font-size: small;
  }
  .btn1-forget {
    width: 180px;
  }

  .container-forget h1 {
    text-align: center;
    font-size: 25px;
  }
}
