.TeamWrapper {
  padding: 100px 0px 50px;
}
.TeamHeader {
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  color: rgb(31, 85, 165);
  margin: 0;
  padding-bottom: 20px;
  padding-top: 50px;
}
.TeamHeader span{
    color: #1175BB;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.TeamBody {
  /* color: rgba(47, 102, 126, 1); */
  color: #000000;
  font-weight: 500;
  font-size: 23px;
  text-align: center;
  line-height: 38px;
  letter-spacing: -0.011em;
}
.ImgSlideShow {
  margin-top: 10px;
  display: grid;
  justify-items: center;
  background-color: none;
  padding: 0px 100px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.side-component {
  grid-template-rows: auto;
}

.swiper-slide img {
  margin: 50px 10px 0;
  display: block;
  width: 250px;
  height: 280px;
  object-fit: cover;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  z-index: 10;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}
.img-text {
  /* display: flex; */
  display: inline;
  align-content: center;
  margin: 0 10px;
  width: 250px;
  height: 180px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.04);
  padding-top: 10px;
  margin-bottom: 50px;
  z-index: 11;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}
.img-text p {
  color: rgb(0, 0, 0);
}

.swiper-slide img:hover {
  z-index: 1000;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
}

.swiper-wrapper {
  height: 400px;
}

.swiper-pagination {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  width: calc(var(--swiper-navigation-size) / 495 * 27) !important;
}

@media (max-width: 1024px) {
  .TeamWrapper {
    text-align: center;
    padding: 50px 0px 50px;
  }
  .TeamHeader {
    font-size: 45px;
  }
  .swiper-slide img {
    width: 240px;
    height: 250px;
  }
  .img-text {
    width: 240px;
  }
}
@media (max-width: 768px) {
  .TeamWrapper {
    padding: 30px 0px;
  }
  .TeamHeader {
    font-size: 38px;
    padding: 10px;
  }
  .TeamBody {
    font-size: 15px;
    line-height: 28px;
  }
  .ImgSlideShow {
    grid-template-columns: repeat(2, auto);
    grid-gap: 30px;
  }
  .swiper-slide img {
    width: 220px;
    height: 230px;
  }
  .img-text {
    width: 220px;
  }
}
@media (max-width: 480px) {
  .ImgSlideShow {
    grid-template-columns: repeat(1, auto);
    grid-gap: 30px;
  }
  .swiper-slide img {
    width: 200px;
    height: 210px;
  }
  .img-text {
    width: 200px;
  }
}

@media (max-width: 450px) {
  .TeamWrapper {
    padding: 20px 0px;
  }
}
