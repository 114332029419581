.beth-total-sections .both-section-style {
  border: 1px solid #fff;
  margin: 15px;
  padding: 27px;
  max-width: 406px;
  margin-left: auto;
  margin-right: auto;
}
.container2 {
  max-width: 1600px;
  margin: 0px auto;
}
* {
  /* --blue-color-400: #008fd3; */
  /* --blue-color-500: #2294d2; */
  /* --blue-color-800: #194daa; */
  --radium-green-600: #3ca899;
  --black-600: #000;
  /* --radium-orange-600: #ff8532; */
  /* --radium-orange-500: #ff7c00; */
  --ency-400: #2c3c4d;
  --ency-600: #21393d;
  --white-color-600: #ffffff;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}
mark {
  color: #000;
  background: #ff0;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0;
}
legend {
  padding: 0;
  border: 0;
  height: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 0;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;

    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: var(--white-color-600) !important;
  }
  .navbar {
    display: none;
  }
  .table td,
  .table th {
    background-color: var(--white-color-600) !important;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
@font-face {
  font-family: "Glyphicons Halflings";

  /* src: url('../../assets/fonts/glyphicons-halflings-regular.eot');
    src: url('../../assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/glyphicons-halflings-regular.woff') format('woff'), url('../../assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../../assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg'); */
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: var(--white-color-600);
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}
a {
  color: #428bca;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #2a6496;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
p {
  margin: 0 0 10px;
}

small,
.small {
  font-size: 85%;
}
cite {
  font-style: normal;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-primary {
  color: #428bca;
}
a.text-primary:hover {
  color: #3071a9;
}
.text-success {
  color: #3c763d;
}
a.text-success:hover {
  color: #2b542c;
}

.text-danger {
  color: #a94442;
}
a.text-danger:hover {
  color: #843534;
}

ul,
ol {
  margin-top: 0;
  /* margin-bottom: 10px; */
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: "\2014 \00A0";
}
.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}
.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
  content: "";
}
.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
  content: "\00A0 \2014";
}
blockquote:before,
blockquote:after {
  content: "";
}
address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: var(--white-color-600);
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1400px;
  }
}
.container-fluid {
  padding-right: 74px;
  padding-left: 74px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1904px;
}
.item-center {
  align-items: center;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.pysician-checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pysician-checkbox-label input {
  width: 10px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.text-align-end {
  text-align: end;
}
.underline {
  text-decoration: underline;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-6 {
  width: 50%;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }

  .col-sm-9 {
    width: 75%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
}
table {
  background-color: transparent;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: var(--white-color-600);
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5;
}
table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}
@media screen and (max-width: 1440px) {
  .container-main {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}
.Membership-data > p {
  font-size: 16px;
  font-weight: 300;
  color: #313131;
  line-height: 28px;
  width: auto;
  margin-bottom: 7px;
}
.member-of-number {
  display: flex;
  justify-content: space-between;
}
.member-btn {
  width: 100% !important;
}
.btn-green {
  color: var(--white-color-600);
  background-color: #5cb85c !important;
  border-color: #5cb85c !important;
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
  color: var(--white-color-600);
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-green:active,
.btn-green.active {
  background-image: none;
}
.btn-green.disabled,
.btn-green[disabled],
fieldset[disabled] .btn-green,
.btn-green.disabled:hover,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-green:hover,
.btn-green.disabled:focus,
.btn-green[disabled]:focus,
fieldset[disabled] .btn-green:focus,
.btn-green.disabled:active,
.btn-green[disabled]:active,
fieldset[disabled] .btn-green:active,
.btn-green.disabled.active,
.btn-green[disabled].active,
fieldset[disabled] .btn-green.active {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: var(--white-color-600);
  /* background-image: none; */
  border: 1px solid #1175bb;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 1;
}
textarea.form-control {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: none;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 34px;
  line-height: 1.42857143 \0;
}
input[type="date"].input-sm,
input[type="time"].input-sm,
input[type="datetime-local"].input-sm,
input[type="month"].input-sm {
  line-height: 30px;
}
input[type="date"].input-lg,
input[type="time"].input-lg,
input[type="datetime-local"].input-lg,
input[type="month"].input-lg {
  line-height: 46px;
}
.form-group {
  margin-bottom: 15px;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
select.input-sm {
  height: 30px;
  line-height: 30px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
.form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}
select.input-lg {
  height: 46px;
  line-height: 46px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .form-control-feedback {
  color: #3c763d;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}
.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}
.has-error .form-control-feedback {
  color: #a94442;
}

@media (min-width: 768px) {
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px;
}
.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
.btn-default {
  color: #333;
  background-color: var(--white-color-600);
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: var(--white-color-600);
  border-color: #ccc;
}
.btn-default .badge {
  color: var(--white-color-600);
  background-color: #333;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.btn-green {
  color: var(--white-color-600);
  background-color: #3ca899;
  border-color: #44bbab;
  display: inline-block;
  padding: 15px 69px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 25px;
  font-weight: 600;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  animation: 1s ease 0s 1 normal forwards running horDsQ;
  height: 38px;
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
  color: var(--white-color-600);
  background-color: #3ca899;
  border-color: #44bbab;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.15) 0px 20px 20px,
    rgba(0, 0, 0, 0.15) 0px 0px 0px 0.5px inset;
}
.btn-green:active,
.btn-green.active {
  background-image: none;
}
.btn-green.disabled,
.btn-green[disabled],
fieldset[disabled] .btn-green,
.btn-green.disabled:hover,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-green:hover,
.btn-green.disabled:focus,
.btn-green[disabled]:focus,
fieldset[disabled] .btn-green:focus,
.btn-green.disabled:active,
.btn-green[disabled]:active,
fieldset[disabled] .btn-green:active,
.btn-green.disabled.active,
.btn-green[disabled].active,
fieldset[disabled] .btn-green.active {
  background-color: #3ca899;
  border-color: #44bbab;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.15) 0px 20px 20px,
    rgba(0, 0, 0, 0.15) 0px 0px 0px 0.5px inset;
}
.horDsQ {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.shipping-btn {
  display: flex;
  gap: 10px;
  justify-content: end;
  border-top: 1px solid var(--blue-color-400);
}
.shipping-btn > .btn-primary {
  background-color: var(--blue-color-400);
  border-color: #357ebd;
  margin: 10px;
  width: 120px;
  font-size: 15px;
  font-weight: 600;
}
.shipping-btn > .btn-primary:hover {
  background-color: #3071a9;
}
.btn-deep-orange {
  color: var(--white-color-600);
  background-color: var(--radium-orange-600);
  border-color: var(--radium-orange-600);
  display: inline-block;
  padding: 15px 50px;
  margin-bottom: 0;
  font-size: 23px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 25px;
  font-weight: 500;
  width: auto;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  animation: 1s ease 0s 1 normal forwards running horDsQ;
}
.btn-deep-orange:hover,
.btn-deep-orange:focus,
.btn-deep-orange:active,
.btn-deep-orange.active {
  color: var(--white-color-600);
  background-color: var(--radium-orange-600);
  border-color: var(--radium-orange-600);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.15) 0px 20px 20px,
    rgba(0, 0, 0, 0.15) 0px 0px 0px 0.5px inset;
}
.btn-deep-orange:active,
.btn-deep-orange.active {
  background-image: none;
}
.btn-deep-orange.disabled,
.btn-deep-orange[disabled],
fieldset[disabled] .btn-deep-orange,
.btn-deep-orange.disabled:hover,
.btn-deep-orange[disabled]:hover,
fieldset[disabled] .btn-deep-orange:hover,
.btn-deep-orange.disabled:focus,
.btn-deep-orange[disabled]:focus,
fieldset[disabled] .btn-deep-orange:focus,
.btn-deep-orange.disabled:active,
.btn-deep-orange[disabled]:active,
fieldset[disabled] .btn-deep-orange:active,
.btn-deep-orange.disabled.active,
.btn-deep-orange[disabled].active,
fieldset[disabled] .btn-deep-orange.active {
  background-color: var(--radium-orange-600);
  border-color: var(--radium-orange-600);
}
.btn-deep-orange .badge {
  color: var(--radium-orange-600);
  background-color: var(--white-color-600);
}

.btn-primary {
  color: var(--white-color-600);
  background-color: #428bca;
  border-color: #357ebd;
  border-radius: 11px;
  min-width: 93px;
  min-height: 39px;
  align-items: center;
  /* height: 40px; */
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: var(--white-color-600);
  background-color: #3071a9;
  border-color: #285e8e;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary .badge {
  color: #428bca;
  background-color: var(--white-color-600);
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown {
  position: relative;
}
.dropdown-toggle:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: var(--white-color-600);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: var(--white-color-600);
  text-decoration: none;
  background-color: #428bca;
  outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover,
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus {
  outline: 0;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child > .btn:last-child,
.btn-group > .btn-group:first-child > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:last-child > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}
.btn-group > .btn-lg + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.container-main {
  padding-top: 15px;
  padding-bottom: 15px;
}
.header-foodts-sections {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}
@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}
.user-info {
  margin-top:8px;
  .btn-header{
    margin:0px;
  }
  }
@media (min-width: 1200px) {
  .user-info {
  display: none;
  margin-top:8px;
  }
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.navbar-collapse.in {
  overflow-y: auto;
}
@media (min-width: 1200px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
}
@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
 
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}
.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}
@media (max-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}
.container > .navbar-header,
  /* .container-fluid > .navbar-header, */
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-brand {
  float: left;
  height: 70px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  margin: 0 5px;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand {
    /* margin-left: -15px; */
    margin-top: 22px;
  }
}

.navbar-nav {
  /* margin: 7.5px -15px; */
}
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
.navbar-nav {
  float: right;
  /* margin-right: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
}
.navbar-nav > li {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-nav.navbar-right:last-child {
  margin-right: -15px;
}
@media (max-width: 1195px) {
  .navbar-nav {
    gap: 17px;
  }
  .navbar-inverse .navbar-nav > li > a{
    padding: 10px;
    font-size: 13px;
  }
}
@media (max-width: 1291px) {
  .navbar-nav {
    gap: 10px;
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar-inverse {
  /* background-color: #222;
    border-color: #080808; */
}
.navbar-inverse .navbar-brand {
  color: #777;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: var(--white-color-600);
  background-color: transparent;
}
.navbar-inverse .navbar-text {
  color: #777;
}
.navbar-inverse .navbar-nav > li > a {
  color: #777;
  font-weight: 500;
  transition: all 0.5s ease-out 0s;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: var(--white-color-600);
  background-color: transparent;
}
.navbar-inverse .navbar-nav > li > a:hover {
  background-color: var(--blue-color-400);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
  border-radius: 23px;
  padding: 13px 21px;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: var(--white-color-600);
  background-color: var(--blue-color-400);
  padding: 13px 21px;
  border-radius: 23px;
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle {
  border-color: #333;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: var(--white-color-600);
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: var(--white-color-600);
  background-color: #080808;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: var(--white-color-600);
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: var(--white-color-600);
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
}
.navbar-inverse .navbar-link {
  color: #777;
}
.navbar-inverse .navbar-link:hover {
  color: var(--white-color-600);
}
.navbar-inverse .btn-link {
  color: #777;
}
.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
  color: var(--white-color-600);
}
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: var(--white-color-600);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
a.label:hover,
a.label:focus {
  color: var(--white-color-600);
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.panel {
  margin-bottom: 20px;
  background-color: var(--white-color-600);
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-body {
  padding: 15px;
}

.panel-heading {
  border-bottom: 1px solid transparent;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
  font-weight: 600;
}
.panel-title > a {
  color: inherit;
}

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:first-child {
  border-top-left-radius: 3px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > thead:first-child
  > tr:first-child
  th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel
  > .table-responsive:first-child
  > .table:first-child
  > tbody:first-child
  > tr:first-child
  th:last-child {
  border-top-right-radius: 3px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:first-child {
  border-bottom-left-radius: 3px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tbody:last-child
  > tr:last-child
  th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel
  > .table-responsive:last-child
  > .table:last-child
  > tfoot:last-child
  > tr:last-child
  th:last-child {
  border-bottom-right-radius: 3px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
  border-top: 1px solid #ddd;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .table-responsive {
  margin-bottom: 0;
  border: 0;
}
.panel-default {
  border-color: #ddd;
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid var(--radium-info-600);
}
.panel-default > .panel-heading {
  color: #333;
  /* background-color: #f5f5f5; */
  border-color: var(--radium-info-600);
  margin-bottom: 8px;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.swiper {
  width: 100%;
  height: 100%;
}
#home-video-main {
  padding: 0 74px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  justify-content: center;
  align-items: center;
  display: block;
}

.swiper-slide img {
  margin: auto;
  display: block;
  width: 100%;
  height: auto;
  box-shadow: none;
}
.swiper-button-prev {
  /* left: -4px; */
}
@media (max-width: 1330px) {
  .swiper-button-next:after,
  .swiper-button-prev:after{
    font-size: 24px;
  }
}
@media (max-width: 1330px) {
  .swiper-button-prev {
    left: 10px;
  }
}

@media (max-width: 1024px) {
  #home-video-main {
    padding: 0 43px;
  }
}
@media (max-width: 760px) {
  #home-video-main {
    padding: 0 20px;
  }
  .swiper-button-next {
    right: 20px;
    transform: rotate(90deg);
  }

  .swiper-button-prev {
    left: 20px;
    transform: rotate(90deg);
  }
}
.read-more-button {
  width: auto;
  background-color: #ffffff;
}

.container:before,
.container:after,
.row:before,
.row:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.panel-body:before,
.panel-body:after {
  display: table;
  content: " ";
}
.container:after,
.container-fluid:after,
.row:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.panel-body:after {
  clear: both;
}

.pull-right {
  float: right !important;
}

.show {
  display: block !important;
  opacity: 1;
}

@-ms-viewport {
  width: device-width;
}

/*# sourceMappingURL=bootstrap.css.map */
/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> global settings needed for thickbox <<<-----------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "FontAwesome";
  /* src: url('../fonts/fontawesome-webfont.eot?v=4.3.0');
    src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg'); */
  font-weight: normal;
  font-style: normal;
}
.pull-right {
  float: right;
}
.fa.pull-right {
  margin-left: 0.3em;
}
/* --------------------------------------
  =========================================
  RESPONSIVE FIXES
  =========================================
  -----------------------------------------*/

@media (max-width: 991px) {
  body {
    font-size: 15px;
    line-height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 80%;
  }

  body {
    font-size: 15px;
    line-height: 26px;
  }

  .navbar-inverse .navbar-nav {
    /* padding-left: 10px; */
    line-height: normal;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .navbar-inverse .navbar-nav > li {
    /*display: inline-block;*/
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 65%;
  }
  #home-video-main {
    padding: 0 10px;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 60.5%;
  }
}

/*overriding bootstrap link color */
.navbar-inverse .navbar-nav > li > a {
  color: var(--radium-info-600);
}

/* override bootstrap */
.navbar {
  /*min-height: 106px;*/
  margin-bottom: 0;
}

#mainmenu {
  background-color: #ffffff;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #75aee2;
}

.navbar-brand {
  padding: 0;
}
.navbar-brand img {
  max-height: 100%; /* Makes sure the image does not exceed the container */
  width: auto; /* Keeps the image's aspect ratio */
  object-fit: contain; /* Ensures the image fits inside without cropping */
}

a:focus,
a:hover,
.grid-tit a:hover {
  color: #ff0052;
}

.navbar-inverse .navbar-nav > li > a {
  color: var(--radium-info-600) !important;
  display: flex;
  align-items: center;
  gap: 7px;
}

.navbar-header-top {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border-bottom: 1px solid var(--blue-color-400);
}
.navbar-header-shadow {
  border-bottom: none;
  box-shadow: rgba(0, 75, 117, 0.2) 0px 10px 50px;
}
@media (max-width: 1120px) {
  .navbar-inverse .navbar-brand img {
    width: 133px;
  }
  .navbar-inverse .navbar-nav {
    gap: 16px;
  }
  .navbar-inverse .navbar-nav > li > a {
    font-size: 11px;
  }
  .navbar-inverse .navbar-nav > li > a img {
    width: 18px;
  }
}
@media (max-width: 767px) {
  .header-foodts-sections .navbar-header {
    width: 100%;
  }
}
/* @import url("https://fonts.cdnfonts.com/css/omnes"); */

html {
  font-size: 100%;
}

body {
  background: #ffffff;
  font-family: Omnes, sans-serif;
  /* font-family: Helvetica Neue, Helvetica, Arial; */
  font-size: 16px;
  font-weight: 300;
  color: #313131;
  line-height: 28px;
  overflow-x: hidden !important;
  margin: auto !important;
}

.content-offset {
  padding-top: 19px;
}
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

a {
  -webkit-transition: all ease 0.25s;
  transition: all ease 0.25s;
}

a:hover {
  text-decoration: none;
}

.btn:focus,
.btn:active {
  outline: inherit;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}
@font-face {
  font-family: "Lane";
  /* src: url(../fonts/lanenar_Lane.eot);
      src: url(../fonts/lanenar_Lane.eot?#iefix) format('embedded-opentype'), url(../fonts/lanenar_Lane.woff) format('woff'), url(../fonts/lanenar_Lane.ttf) format('truetype'), url(../fonts/lanenar_Lane.svg#Lane) format('svg'); */
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Lane";
    /* src: url('../fonts/lanenar_Lane.svg#Lane') format('svg'); */
  }
}

/* HEADINGS */

h1,
h2 {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 3.75rem;
  line-height: 4.688rem;
}

h2 {
  font-size: 3.125rem;
  line-height: 4.063rem;
}

h3 {
  font-size: 21px;
  line-height: 38px;
  font-weight: 300;
}

h4 {
  font-size: 20px;
  line-height: 40px;
  font-weight: 300;
  text-align: left;
}

h5 {
  font-size: 18px;
  font-weight: 300;
}

/*---------------------------------------
     SECTION:  FOOTER
  -----------------------------------------*/
footer {
  clear: both;
}

footer ul li {
  list-style-type: none;
  margin: 5px;
  padding: 10px;
  display: inline;
}

footer a {
  color: #009bda;
}

hr {
  background-color: var(--radium-info-600);
  height: 2px;
}

.emergency-input {
  padding: 9px 14px 9px 14px !important;
}

.clients-say-businesses {
  color: var(--radium-info-600);
  font-size: 41px;
  font-weight: 600;
  line-height: 63.984px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}

.integration-description {
  color: #808080;
  font-size: 23px;
  font-weight: 400;
  line-height: 38px;
  max-width: 1341px;
  margin: 0 auto;
  text-align: center;
}

.integration-card-block {
  border-radius: 16px;
  background: var(--blue-color-400);
  padding: 23px;
  margin: 19px 0;
}
.integration-card-inner-block {
  align-items: center;
}
.integration-details {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
  line-height: 37px;
  margin: 0;
  padding: 0;
}
.our-Details-step-section {
  display: flex;
  gap: 30px;
  align-items: center;
  padding-bottom: 25px;
}
.our-Details-step-left {
  color: #283646;
  font-size: 32px;
  font-weight: 600;
  line-height: 67px;
}
.individual-first-sections {
  padding: 70px 0;
}
.life-inner-block {
  display: flex;
  margin-top: 25px;
}
.life-inner-content-img {
  border-radius: 12px;
  border: 3px solid #fff;
}
/* .css-class-for-non-first-elements{
     margin-left: -60px;
    } */
.individuals-section-title {
  color: #2c3c4d;
  font-size: 70px;
  font-weight: 700;
  line-height: 63px;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}
.text-orange {
  color: var(--radium-orange-600);
}
.individual-sub-title {
  color: #000;
  font-size: 32px;
  font-weight: 400;
  line-height: 34px;
}

.become-member-data {
  display: flex;
  justify-content: center;
  margin: 21px 0;
}
.our-Details-step-right {
  color: rgba(0, 15, 32, 0.5);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  max-width: 280px;
  font-family: Omnes, sans-serif;
  margin-bottom: 0;
}
.take-control-sections {
  color: var(--blue-color-400);
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.92px;
  font-family: Omnes, sans-serif;
}
/* ------------ */
.individual-main-sections {
  padding: 60px 0;
}

.resources-inner-section {
  padding-bottom: 50px;
}

.show-form-grop {
  border-radius: 30px;
  border: 3px dashed var(--radium-orange-600);
  background: #f9fbfc;
  padding: 31px;
}
.show-form-control {
  border-radius: 42px;
  border: 1px solid var(--radium-orange-600);
  padding: 23px 14px;
}
.show-form-textarea {
  border-radius: 24px;
  border: 1px solid var(--radium-orange-600);
}
.show-form-button {
  justify-content: center;
  display: flex;
}
.form-control-comments {
  margin-bottom: 15px;
}
.show-form-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.contact-shortly {
  max-width: 1024px;
  margin: 0 auto;
}
.shortly-title {
  color: #2c3c4d;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.second-sections .clients-details {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: Omnes, sans-serif;
  text-align: center;
}
.clients-user-name {
  color: rgb(18, 52, 68);
  font-size: 18px;
  font-family: Omnes, sans-serif;
  margin: 23px 0;
  font-weight: bold;
  text-align: center;
}

.our-out-style:first-child,
.clients-inner-block:nth-of-type(odd) {
  border-right: ridge;
  text-align: center;
}
.our-out-style,
.clients-inner-block {
  border-bottom: ridge;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.our-out-style:last-child {
  max-width: 1600px;
  margin: 0 auto;
}
.our-out-style:last-child,
.clients-inner-block:nth-last-child(-n + 2) {
  border-bottom: none;
}

.clients-works-section {
  margin: 30px 0;
}
.clients-second-sections {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  padding: 22px 0;
  max-width: 1064px;
  margin: auto;
}
.partnership-benefits-inner {
  margin-top: 20px;
  margin-bottom: 40px;
}
.partnership-benefits-detalis {
  color: #808080;
  text-align: center;
  font-size: 23px;
  font-weight: 400;
  line-height: 38px;
  max-width: 921px;
  margin: 0 auto;
}
.border-orange {
  border-radius: 14px;
  border: 3px dashed #ff8532;
}
.border-sky-blue {
  border-radius: 14px;
  border: 3px dashed var(--blue-color-400);
}
.border-gray-900 {
  border-radius: 14px;
  border: 3px dashed #b5a488;
}
.border-green {
  border-radius: 14px;
  border: 3px dashed #6f8a86;
}
.border-green-600 {
  border-radius: 14px;
  border: 3px dashed #63b9ad;
}
.border-yellow {
  border-radius: 14px;
  border: 3px dashed #ffba33;
}
.border-blue {
  border-radius: 14px;
  border: 3px dashed var(--radium-info-600);
}
.border-black {
  border-radius: 14px;
  border: 3px dashed #000;
}

.partner-sections {
  color: var(--radium-info-600);
  font-size: 41px;
  font-weight: 600;
  line-height: 63.984px;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
  text-align: center;
}
.partner-data {
  color: #808080;
  font-size: 23px;
  font-weight: 400;
  line-height: 38px;
}
.members-Card-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(610px, 1fr));
  gap: 39px;
  max-width: 1374px;
  margin: 0 auto;
}

.membersCard-inner-block:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.membersCard-inner-block {
  height: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.3);
  mix-blend-mode: normal;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 4px;
  border-radius: 40px;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.5s ease 0s;
  width: 100%;
  padding: 32px;
}
.members-card-membership {
  display: flex;
  align-items: center;
  gap: 31px;
}
.members-card-membership-style {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  padding: 0;
  width: fit-content;
}
.second-sections .span-business {
  color: #354696;
  font-family: "Omnes", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.4%;
  margin: 0%;
  padding: 0%;
}

.partner-card-block {
  background-color: var(--blue-color-400);
  border-radius: 25px;
  padding: 32px;
  margin: 12px 0;
}
.partner-description {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
  line-height: 37px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.platform-inner-block {
  display: flex;
  align-items: center;
}
.benefits-data-description {
  color: var(--radium-info-600);
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
}
.benefits-content {
  border-radius: 45px;
  background: var(--blue-color-400);
  height: 91%;
}
.benefits-content-sections {
  display: flex;
  justify-content: center;
}
.benefits-content-img {
  border: 4px solid var(--blue-color-400);
  border-radius: 31px;
  margin-top: -16%;
}
.benefits-content h5 {
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-weight: 400;
  line-height: 32px;
  padding: 22px;
  margin: 0;
}
.partnership-content-card {
  margin: 74px 0;
}
.partnership-content-main-card {
  max-width: 1580px;
  margin: 0 auto;
}
.partnership-content-block {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
  grid-column-gap: 49px;
}
.integration-title {
  color: var(--radium-info-600);
  font-size: 41px;
  font-weight: 600;
  line-height: 63.984px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}

@media (max-width: 767.98px) {
  .integration-card-img {
    margin: 0 auto;
  }
  .ready-start-block {
    padding: 13px;
  }
  .partner-sections {
    font-size: 29px;
    line-height: normal;
    margin: 10px 0;
  }
  .clients-say-businesses {
    font-size: 29px;
  }
  .integration-title {
    font-size: 29px;
    line-height: normal;
  }
  .integration-description,
  .partnership-benefits-detalis {
    line-height: 28px;
    text-align: justify;
    font-size: 18px;
  }
  .partner-data {
    line-height: 28px;
    text-align: justify;
    font-size: 18px;
  }
}

@media (max-width: 1286px) {
  .members-Card-data {
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  }
  .individual-main-sections {
    padding: 20px 0;
  }
  .individual-first-sections {
    padding: 28px 0;
  }
}
@media (max-width: 986px) {
  .partner-inner-section {
    justify-content: center;
  }
  .platform-inner-block img {
    max-width: 50%;
    margin: 0 auto;
  }
  .partner-description {
    font-size: 19px;
    text-align: center;
    line-height: 32px;
  }
  .partner-card-block {
    padding: 16px 32px;
  }
  .partnership-content-card {
    margin: 52px 0;
  }
  .clients-works-section,
  .clients-say-businesses {
    margin: 0;
  }
  .our-out-style:first-child {
    border-right: 0;
  }
  .clients-user-name {
    margin-bottom: 0;
    margin-top: 11px;
  }
  .our-out-style,
  .clients-inner-block {
    padding: 10px;
  }
  .clients-inner-block:nth-of-type(odd) {
    border-right: 0;
  }
  .our-out-style:nth-last-child(-n + 2),
  .clients-inner-block:nth-last-child(-n + 2) {
    border-bottom: 1px solid #d9d9d991;
  }
  .members-Card-data {
    grid-template-columns: repeat(auto-fit, minmax(358px, 1fr));
    gap: 21px;
  }
  .members-card-membership-style {
    font-size: 15px;
    line-height: 19px;
  }
  .members-card-membership {
    gap: 10px;
  }
  .individual-main-sections {
    padding: 10px 0;
  }
  .individuals-section-title {
    font-size: 41px;
    line-height: 63px;
    margin: 0;
  }
  .individual-sub-title {
    font-size: 18px;
    line-height: 19px;
  }
  .life-inner-block {
    margin-top: 0;
  }
  .take-control-sections {
    font-family: Omnes, sans-serif;
    font-size: 19px;
  }
  .our-Details-step-section {
    padding-bottom: 9px;
  }
  .our-Details-step-right {
    font-size: 16px;
    padding-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .members-Card-data {
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  }
  .integration-details {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
  .clients-user-name {
    margin: 8px 0 0;
  }
}

@media (max-width: 432px) {
  .shortly-title {
    color: #2c3c4d;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
  }
  .show-form-grop {
    padding: 18px;
  }
  .integration-title {
    margin-top: 0;
  }
  .partnership-content-card {
    margin: 32px 0;
  }
  .members-Card-data {
    grid-template-columns: repeat(auto-fit, minmax(297px, 1fr));
  }
  .clients-say-businesses {
    font-size: 30px;
    line-height: 31.984px;
  }
  .second-sections .clients-details {
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
  }
  .clients-user-name {
    line-height: 20px;
    text-align: justify;
    font-size: 14px;
  }
  .clients-second-sections {
    font-size: 18px;
    line-height: 23px;
    padding: 13px 0;
    text-align: justify;
  }
} /* CSS Document */

body {
  margin: 0px;
  padding: 0px;
  /* make sure body isn't narrower than content*/
  /*min-width: 1080px;*/
  background-color: #fff;
  -webkit-background-size: cover;
  background-size: cover;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.title {
  height: 162px;
  width: 552px;
  margin: 0px 94px 0px 43px;
  top: 50px;
  float: left;
  position: relative;
}

/*-----------------------------------------------------------*/

table,
td,
div,
img {
  border: 0;
  border-collapse: collapse;
  vertical-align: middle !important;
}
td {
  vertical-align: none;
}
ul,
ul li {
  list-style: none;
  list-style-image: none;
}
p {
  padding: 0px 0px 5px 0px;
}

a {
  text-decoration: none;
  color: #45b1d6;
  outline: none;
}
a:hover {
  text-decoration: none;
}
label {
  font-size: 14px;
  font-family: "Omnes", sans-serif;
  /* font-family:"Arial Black", sans-serif; */
  color: #000;
  font-weight: 400;
  line-height: 1.4375em;
}

.user-seeting-label {
  color: #2a3547;
  text-align: start;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 700;
  /* padding-bottom: 15px; */
  /* padding-top: 20px; */
  text-transform: uppercase;
}
.subitem-sidebar{
  text-align: start;
  margin: 17px 9px 0;
  /* background: var(--radium-info-600); */
  padding: 3px 8px;
  margin-top: 4px;
  border-radius: 6px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.sidebar-header-section {
  padding-left: 30px;
  padding-right: 30px;
  border-top: 1px solid #1175bb;
  border-bottom: 1px solid #1175bb;
  /* margin-top:60px */
}

/* Emergency Login Forms*/

td.text-center.ng-binding {
  text-align: center;
}

nav.dropdown-menu a {
  display: block;
  padding: 0.2em 1em;
}

nav.dropdown-menu a:hover {
  background: #336699;
}

form fieldset {
  border: 1px solid #c8e6f1;
  margin: 5px 0px auto;
  width: 98%;
}

/*  ------------------------------New Styles -----------------------------*/

.dropdown-toggle {
  cursor: pointer;
}
.dropdown-menu {
  cursor: pointer;
}

/* .cards li{-webkit-transition:all .2s;-moz-transition:all .2s;-ms-transition:all .2s;-o-transition:all .2s;transition:all .2s;background-image:url(../images/card_logos.png);background-position:0 0;float:left;height:32px;margin-right:2px;text-indent:-9999px;width:45px} */

.error {
  color: #b94a48;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.success-message {
  color: #069;
  font-weight: bold;
}

td.ng-binding {
  color: #000000;
}

.table-responsive td a:hover {
  color: #ff0052;
}
.table-responsive td a {
  color: #1175bb;
}

.emergency-profile-container .personal-information table thead {
  background-color: transparent;
}
.emergency-profile-container .emergency-profile-title {
  color: var(--radium-info-600);
  text-align: center;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 36px;
  font-weight: 500;
}
.emergency-profile-container .table-title-contents {
  /* padding: 0 14px 1px; */
  border-radius: 9px;
  border: 0.5px solid var(--radium-info-600);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 50px 100px 0px rgba(0, 75, 117, 0.3);
  backdrop-filter: blur(20px);
}
.emergency-personal-information {
  max-width: 682px;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 700px;
}
@media (max-width: 700px) {
  .emergency-personal-information{
    width: calc(100vw - 30px);
  }
}

.emergency-profile-container .avatar-container {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  margin-bottom: 11px;
}
.emergency-profile-container .emergency-profile-info-bottom {
  padding: 0 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.emergency-profile-container .table-title-info {
  color: var(--radium-info-600);;
  text-align: center;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 27px;
  font-weight: 500;
  margin-top: 10px;
}

/* .emergency-profile-container table thead {
  background-color: #b9e2f2;
} */
@media print {
  a {
    display: none !important;
  }
  button {
    display: none !important;
  }
}
.border-radius-12{
  border-radius: 12px;
}
.legado-section{
  position: relative;
  border: 1px solid #000;
  background: var(--primary-BackgroundColor-600) !important;
}
.legado-section .legado-video-text{
  color:#000;
}
.legado-section .legadoBox-section{
  position: absolute !important;
  width: auto;
  max-width: 100%;
  max-height: 82px;
  top: -42px;
  left: -50px;
  border-radius: 6px;
  padding: 10px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  background: linear-gradient(to left, transparent 69%, #000 20%) bottom center / 100% 1px no-repeat,
   linear-gradient(to top, transparent 51%, #000 50%) right center / 1px 100% no-repeat, var(--primary-BackgroundColor-600);
}
.legado-section .legadoBox-section img{
  width: auto;
  max-width: 100%;
  max-height: 59px;
}

.home-video {
  background-color: var(--blue-color-400);
  color: white;
  padding-top: 38px;
  padding-bottom: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-video .video-section > div {
  position: relative;
}
.home-video .video-section > div #video-snapshot {
  position: absolute;
}
.home-video .video-section > div #video-snapshot {
  z-index: 1000;
  display: inline-block;
}
/*---------------------------------------
     SECTION:  SERVICES
  -----------------------------------------*/
.services {
  -webkit-background-size: cover;
  background-size: cover;
}
.services .single-service h3 {
  margin-top: 20px;
  font-family: Verdana;
  color: #008ed6;
  text-transform: uppercase;
}
.services .single-service .service-icon {
  font-size: 80px;
}
.services .single-service .service-inner {
  color: #313131;
  position: relative;
  min-height: 360px;
  background-color: #fff;
  padding: 2em;
  opacity: 0.6;
}
.services .single-service .service-inner .text-center {
  margin-bottom: 40px;
}

.video-letter-2 {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 35px;
  padding: 14px 0;
  /* max-width: 546px; */
  /* margin-bottom: 27px; */
}
.peace-main-section {
  padding: 50px 74px;
  background-color: #fff;
}
.peace-main-section .peace-title {
  font-size: 65px;
  font-style: normal;
  font-weight: 800;
  line-height: 112.4%;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
  color: var(--blue-color-400);
  margin-bottom: 37px;
  margin-top: 0;
}
.peace-main-section .peace-title span {
  color: var(--blue-color-800);
}
.peace-main-section .carousel-inner-peace {
  border-radius: 24px;
  border: 1px solid #06476c;
  height: auto;
}
.peace-main-section .carousel-inner-peace .table-carousel-left {
  display: table;
  height: 100%;
  margin: 0 auto;
}
.peace-main-section
  .carousel-inner-peace
  .table-carousel-left
  .table-carousel-inner {
  display: table-cell;
  vertical-align: middle;
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  max-width: 650px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-family: Omnes, sans-serif;
}
.peace-main-section .slick-slder-data {
  position: relative;
}
.peace-main-section .slick-slder-data .img-carousel {
  width: 100%;
  max-height: inherit;
}
.peace-main-section .slick-slder-data .carousel-indicators-peace {
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 32px 0;
  bottom: 0;
  width: auto;
  position: relative;
}
.peace-main-section .slick-slder-data .carousel-indicators-peace li {
  background-color: #d9d9d9;
  width: 47px;
  height: 10px;
  margin: 1px;
}
.peace-main-section .slick-slder-data .carousel-indicators-peace .active {
  background-color: #ff8532;
  width: 76px;
}
.peace-main-section .home-control-img {
  height: 350px;
}
.peace-main-section .home-control-img img {
  border: 1px solid #5dbae6;
  border-radius: 24px;
  height: -webkit-fill-available;
}
.peace-main-section .peace-inner-section .swiper-pagination {
  position: relative;
  top: 0;
  margin: 26px 0;
}
.peace-main-section
  .peace-inner-section
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 66px;
  opacity: 0.7;
  height: 20px;
  background-color: #d9d9d9;
  border-radius: 15px;
  cursor: pointer;
}
.peace-main-section
  .peace-inner-section
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #ff8532;
  opacity: 1;
  width: 90px;
}

.organization-section {
  margin: 35px 0 35px;
}
.organization-section .organization-section-block {
  border-radius: 24px;
  border: 1px solid #06476c;
  background: #f9f9f9;
  padding: 36px 0;
}
.organization-section .organization-section-block .organization-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 0;
}
.organization-section .organization-section-block .organization-dec {
  text-align: center;
  font-size: 16px;
  line-height: 25px;
}
.organization-section .organization-section-block .organization-img-section {
  max-width: 1135px;
  margin: 0 auto;
}
.organization-section
  .organization-section-block
  .organization-img-section
  .swiper-slide {
  background-color: transparent;
}
.organization-section
  .organization-section-block
  .organization-img-section
  .swiper-wrapper {
  align-items: center;
}

.latest-article {
  margin: 70px 0;
}

.article-main-sections .latest-news-style {
  padding: 0px 0 !important;
}
.article-main-sections .latest-news-title {
  color: var(--radium-info-600);
  font-size: 48px;
  font-weight: 800;
  line-height: 94px;
  text-align: center;
  padding: 27px 0;
}
.article-main-sections .article-inner-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.3);
  mix-blend-mode: normal;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 4px;
  border-top: 1px solid;
  transition: all 1.5s ease 0s;
  cursor: pointer;
}
.article-main-sections .article-inner-block:hover {
  box-shadow: rgba(0, 75, 117, 0.3) 20px 10px 50px;
  transition: all 1.5s ease 0s;
}
.article-main-sections .article-inner-sections {
  grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
  display: grid;
  gap: 25px;
}
.article-main-sections .resources-inner-sections {
  grid-template-columns: repeat(auto-fit, minmax(377px, 1fr));
  display: grid;
  gap: 25px;
}
@media (max-width: 1709px) {
  .article-main-sections .resources-inner-sections {
    grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
  }
}
@media (max-width: 1024px) {
  .article-main-sections .latest-news-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media (max-width: 519px) {
  .article-main-sections .latest-news-title {
    font-size: 29px;
    line-height: 31px;
    padding: 0px;
    margin: 15px 0;
    font-weight: 500;
  }
  .article-main-sections .resources-inner-sections {
    grid-template-columns: auto;
  }
  .organization-section {
    margin: 20 0;
  }
}
.article-main-sections .article-inner-title {
  color: #123444;
  font-size: 18px;
  font-family: Omnes, sans-serif;
  font-weight: bold;
  line-height: normal;
  margin-top: 0;
}
.article-main-sections .article-inner-description {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  font-family: Omnes, sans-serif;
}
.article-main-sections .border-top-orange {
  border-top: 2px solid var(--radium-orange-600);
}
.article-main-sections .border-top-sky-blue {
  border-top: 2px solid var(--radium-info-600);
}
.article-main-sections .border-top-green {
  border-top: 2px solid #63b9ad;
}
.article-main-sections .border-top-black {
  border-top: 2px solid #000;
}
.article-main-sections .border-top-sky-yellow {
  border-top: 2px solid #ffba33;
}
.article-main-sections .border-top-red {
  border-top: 2px solid #ea5353;
}
.article-main-sections .article-inner-link {
  margin-bottom: 12px;
}
.article-main-sections .article-inner-link a {
  cursor: pointer;
}
.article-main-sections .article-inner-btn {
  display: flex;
  justify-content: flex-end;
}
.article-main-sections .article-inner-btn button {
  background-color: var(--radium-info-600);
  color: #fff;
  padding: 11px 18px;
  border-radius: 10px;
  font-weight: 500;
}
.beth-total-sections {
  padding: 13px;
  border-radius: 24px;
  margin: 0;
  background: var(--blue-color-400);
}
.beth-total-sections .beth-total-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 57px;
  text-transform: capitalize;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  font-family: Omnes, sans-serif;
  color: #fff;
}
.beth-total-sections .beth-total-title span {
  color: var(--radium-orange-600);
  font-size: 56px;
}

.beth-total-sections .beth-total-description {
  color: #fff;
  font-size: 23px;
  font-weight: 200;
  line-height: 24px;
}
.beth-total-sections .key-light-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.weekend-traveling-section {
  margin: 45px 0;
}
.weekend-traveling-section .swiper-slide {
  text-align: start;
}
.weekend-traveling-section .swiper-horizontal > .swiper-pagination-bullets,
.weekend-traveling-section
  .swiper-pagination-bullets.swiper-pagination-horizonta {
  width: 22%;
}
.weekend-traveling-section .swiper-pagination-bullet-active {
  background-color: #fff !important;
}
.weekend-traveling-section .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 1px solid var(--white-color-600);
  opacity: 1;
}
@media (max-width: 993px) {
  .weekend-traveling-section .swiper-horizontal > .swiper-pagination-bullets,
  .weekend-traveling-section
    .swiper-pagination-bullets.swiper-pagination-horizonta {
    width: 100%;
    bottom: -3px;
  }
  .article-main-sections .article-inner-block {
    padding: 20px;
  }
  .weekend-traveling-section {
    margin: 30px 0;
  }
  .weekend-traveling-section .swiper-pagination-bullet-active {
    background-color: #008fd3 !important;
  }
  .weekend-traveling-section .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid #008fd3;
  }
}
.weekend-traveling-section .carousel-indicators {
  left: 10%;
  width: auto;
  margin-left: 0;
}
.weekend-traveling-section .carousel-indicators li {
  background-color: #fff;
}
.weekend-traveling-section .carousel-indicators .active {
  background-color: transparent;
  width: 10px;
  height: 10px;
  margin: 1px;
}
.weekend-traveling-section .weekend-fluid {
  width: 100%;
}
.weekend-traveling-section .table-carousel-left {
  display: table;
  height: 100%;
  margin: 0 auto;
}
.weekend-traveling-section .table-carousel-inner {
  color: rgba(0, 0, 0, 0.73);
  font-size: 22px;
  font-weight: 700;
  line-height: 37px;
  max-width: 676px;
  display: table-cell;
  vertical-align: middle;
  font-family: Omnes, sans-serif;
}

.services-sections .services-section-title {
  color: var(--radium-info-600);
  font-size: 48px;
  font-weight: 800;
  line-height: 94px;
  margin-top: 0;
}
.services-sections .service-sections-description {
  color: rgba(0, 0, 0, 0.51);
  font-size: 21px;
  font-weight: 500;
  line-height: 1.5;
}

.services-overview-card {
  margin: 50px 0;
}
.services-overview-card .service-item-block {
  font-size: 25px;
  font-weight: 700;
  line-height: 48px;
}
.services-overview-card .text-sky-blue {
  color: var(--blue-color-400);
}
.services-overview-card .text-green {
  color: var(--radium-green-600);
}
.services-overview-card .text-gray {
  color: #b5a488;
}
.services-overview-card .text-orange {
  color: var(--radium-orange-600);
}

.latest-news-sections .latest-news-title {
  color: var(--radium-info-600);
  font-size: 48px;
  font-weight: 800;
  line-height: 94px;
  text-align: center;
}
.latest-news-sections .latest-card {
  display: flex;
  justify-content: center;
}
.latest-news-sections .bg-dark-blue {
  background-color: #1122b8;
}
.latest-news-sections .bg-ency-600 {
  background-color: #0f496b;
}
.latest-news-sections .bg-gray {
  background-color: #567bb0;
}
.latest-news-sections .latest-news-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 75px;
}
.latest-news-sections .latest-news-card-inner {
  padding: 25px;
  border-radius: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 30px 50px 0px rgba(0, 75, 117, 0.2);
  backdrop-filter: blur(20px);
  border: 2px solid #249fda;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
}
.latest-news-sections .latest-news-card-inner:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 20px 10px 50px rgba(0, 75, 117, 0.3);
}
.latest-news-sections .latest-get-date {
  color: var(--radium-info-600);
  font-size: 41px;
  font-weight: 800;
  line-height: 67px;
}
.latest-news-sections .latest-news-card-label {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin-bottom: 36px;
  margin-top: 36px;
}
.latest-news-sections .latest-button {
  width: 100%;
  justify-content: center;
  display: flex;
}
.latest-news-sections .latest-button button {
  padding: 10px 56px;
  color: #000;
  font-size: 19px;
  font-weight: 500;
  line-height: 30px;
  border-radius: 15px;
    display: none;
}

@media (max-width: 1199px) {
  .services .single-service .service-inner {
    min-height: 415px;
  }
  .response-main-section {
    padding: 21px 0;
  }
  .response-title {
    font-size: 61px;
    line-height: normal;
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .services h2 {
    font-size: 28px;
  }
  .services .single-service {
    /* put spacing between the white-background */
    margin-bottom: 2em;
  }
  .services .single-service .service-inner {
    min-height: 0;
  }
  .video-letter-2 {
    margin-bottom: 0px;
  }
  .response-connections-block {
    font-size: 18px;
    line-height: inherit;
  }
  .response-connections {
    gap: 0;
  }
}

/* VIDEO */
video {
  max-width: 100%;
  height: auto;
}

iframe,
embed,
object {
  max-width: 100%;
}

.video-container {
  width: 90%;
  background: transparent !important;
  margin: 40px auto 50px;
}

.video-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-play {
  border-radius: 24px;
}
.video {
  background: transparent !important;
}

.video-letter {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 67px;
  font-style: normal;
  font-weight: 500;
  line-height: 73px;
  margin: 0%;
  padding: 0%;
  border-bottom: 1px solid;
  padding-bottom: 7px;
  max-width: 550px;
}
.video-letter .text-blue {
  color: var(--radium-info-600);
}
.video-letter .text-gray {
  color: #bddcfd;
}
.video-letter .text-sky-blue {
  color: #83e4fd;
}

@media (max-width: 1400px) {
  .beth-total-sections .beth-total-title {
    font-size: 34px;
  }
  .beth-total-sections .beth-total-title span {
    font-size: 34px;
  }
  .beth-total-sections .both-section-style {
    border: 1px solid #fff;
    margin: 10px;
    padding: 10px;
  }
  .beth-total-sections .beth-total-description {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .beth-total-sections .beth-total-title {
    font-size: 28px;
    line-height: 23px;
  }
  .beth-total-sections .beth-total-description {
    font-size: 15px;
  }
}
@media (max-width: 1024px) {
  .legado-section .legadoBox-section{
    left:-1px;
    border-radius: 6px 6px 6px 0px;
    background: linear-gradient(to left, transparent 100%, #000 0%) bottom center / 100% 1px no-repeat, linear-gradient(to top, transparent 50%, #000 21%) right center / 1px 100% no-repeat, var(--primary-BackgroundColor-600);
  }
  
}

@media (max-width: 992px) {
  .legado-section .legadoBox-section img{
    max-height: 43px;
  }

  .home-video {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .video-letter {
    line-height: 48px;
    max-width: 510px;
    font-size: 47px;
  }
  .video-div {
    display: inline;
  }
  .video-section > div {
    margin: 10px 0;
  }
  .peace-main-section {
    padding: 15px 15px;
  }
  .peace-main-section .peace-title {
    font-size: 29px;
    line-height: normal;
    margin: 0;
  }
  .peace-main-section .slick-slder-data .carousel-indicators-peace {
    margin: 14px 0;
  }
  .peace-main-section .carousel-inner-peace .img-content {
    justify-content: center;
  }
  .peace-main-section
    .carousel-inner-peace
    .table-carousel-left
    .table-carousel-inner {
    font-size: 21px;
    line-height: 30px;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .services-overview-card {
    margin: 17px 0;
  }
  .latest-news-sections .latest-news-card {
    gap: 30px;
  }
  .latest-news-sections .latest-news-title {
    line-height: normal;
    font-size: 37px;
  }
  .latest-news-sections .latest-get-date {
    line-height: normal;
    font-size: 27px;
  }
  .latest-news-sections .latest-get-date p {
    margin-bottom: 0px;
  }
  .organization-section .organization-section-block {
    padding: 14px 0 0 0;
  }
  .organization-section .organization-section-block .organization-title {
    font-size: 26px;
    line-height: 31px;
  }
  .organization-section .organization-section-block .organization-img-section {
    margin: 11px;
    gap: 9px;
  }
  .services-sections .services-section-title {
    font-size: 30px;
    line-height: 39px;
  }
  .services-sections .service-sections-description {
    font-size: 17px;
    line-height: 32px;
  }
  .latest-article {
    margin: 34px 0;
  }
  .weekend-traveling-section .table-carousel-inner {
    color: rgba(0, 0, 0, 0.73);
    font-size: 21px;
    font-weight: 700;
    line-height: 35px;
  }
}
@media (max-width: 528px) {
  .article-main-sections .article-inner-sections {
    grid-template-columns: auto;
  }
  .weekend-traveling-section {
    margin: 15px 0;
  }
}
@media (max-width: 487px) {
  .key-insights-section {
    width: 100%;
  }
  .weekend-traveling-section .table-carousel-inner {
    font-size: 18px;
    line-height: 27px;
  }
  .video-section > div {
    margin: 0 0;
  }
  .latest-article {
    margin: 28px 0;
  }
}
@media (max-width: 375px) {
  .members-card-membership {
    display: inline-block;
  }
 .members-card-item{
    display: flex;
    justify-content: center;
    max-width: 55px;
    width: 100%;
    margin: auto auto 16px auto;
  }
  .members-card-membership-style{
    text-align: center;
  }
  .membersCard-inner-block{
    padding: 16px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }
  .latest-article {
    margin: 15px 0;
  }
  .article-main-sections .article-inner-block {
    padding: 15px;
  }
  .beth-total-sections .beth-total-title {
    font-size: 18px;
    line-height: 13px;
  }
  .beth-total-sections .beth-total-description {
    font-size: 12px;
    line-height: 14px;
  }
  .latest-news-sections .latest-news-card {
    grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  }
}
@media (max-width: 1772px) {
  .latest-news-sections .latest-news-card {
    grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
    gap: 23px;
  }
}
@media (max-width: 1515px) {
  .latest-news-sections .latest-news-card {
    grid-template-columns: repeat(auto-fill, minmax(257px, 1fr));
  }
  .latest-news-sections .latest-news-card-label {
    font-size: 16px;
    line-height: 23px;
  }
}

body .top-menu-button {
  padding: 0;
  background-color: transparent;
  border: 0;
  width: auto;
  font-weight: 600;
}
body .top-menu-button:focus,
body .top-menu-button:active,
body .top-menu-button:hover {
  background-color: transparent;
  transform: translateY(0px);
}
body .top-menu-items {
  border-radius: 80px 0px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  padding: 30px;
}
body .top-menu-items .btn-outline-primary {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background-color: var(--radium-info-600);
  border-radius: 30px;
  border: 2px solid var(--radium-info-600);
  color: #fff;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  margin: 5px auto;
  padding: 9px 48px !important;
  text-align: center;
  width: 100%;
  transition: all 0.2s;
  padding: 12px 17px;
  align-items: center;
}
body .top-menu-items .btn-outline-primary:hover {
  background-color: var(--white-color-600);
  border: 2px solid var(--radium-info-600);
  color: var(--radium-info-600);
  transition: all 0.2s;
}
body .top-menu-items a {
  padding: 0;
}
body .top-menu-items a:hover {
  background-color: transparent;
}
body > .container {
  min-height: 500px;
}
body .footer-sections {
  margin-top: 50px;
  background-color: var(--blue-color-400);
  padding: 50px 31px 16px 31px;
}
body .footer-second-part {
  display: flex;
}
body .footer-con-style {
  padding-top: 10px;
  padding-bottom: 30px;
}
body .footer-link {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  gap: 10px;
}
body .footer-link li a {
  color: var(--white-color-600);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
body .visit-pawprint-footer {
  margin-top: 35px;
}
body .footer-content-block {
  color: var(--white-color-600);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
body .footer-back {
  color: var(--white-color-600);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
body .footer-back.text-inner-style {
  color: #f60;
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
}
body .footer-bottom {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 10px 0;
  padding: 0;
}
body .social-icons-sections {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, auto);
  padding-top: 20px;
}
body .social-icons-sections img {
  cursor: pointer;
}
body .footer-data-left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
body .footer-btn-section {
  display: flex;
  text-align: start;
  align-items: end;
}
body .footer-btn-section img {
  width: auto;
  border-radius: 10px;
}

body .footer-left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
body .footer-left-content .footer-btn-inner {
  display: flex;
  gap: 51px;
  justify-content: flex-end;
  padding: 31px 0;
}

body > footer a,
body > footer label {
  color: var(--white-color-600) !important;
}
body > footer .footer-left-right {
  color: var(--white-color-600);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  font-size: 20px;
  font-weight: 500;
}
body > footer .footer-link {
  color: var(--white-color-600);
  font-size: 20px;
  font-weight: 500;
}
body > footer .footer-link > li {
  padding-bottom: 10px;
}
body > footer .row-flex {
  display: flex;
  flex-wrap: wrap;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
@media (max-width: 1351px) {
  body > footer .footer-link {
    font-size: 16px;
  }
}

@media (max-width: 781px) {
  body .footer-sections {
    padding: 50px 16px 16px 16px;;
  }
  body .footer-left-content .footer-btn-inner{
    gap: 8px;
  }
}

@media (max-width: 767px) {
  body > footer .footer-left-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  body .footer-sections {
    padding: 17px 17px 16px 12px;
  }
}

@media (max-width: 575.98px) {
  .legado-section{
    margin-top:20px;
  }
  .legado-section .legadoBox-section {
    padding: 5px;
    background: linear-gradient(to left, transparent 100%, #000 0%) bottom center / 100% 1px no-repeat, linear-gradient(to top, transparent 23%, #000 22%) right center / 1px 100% no-repeat, var(--primary-BackgroundColor-600);
  }
  .video-letter {
    line-height: 27px;
    font-size: 24px;
  }
  .video-letter-2 {
    font-size: 17px;
    line-height: normal;
  }
  body .footer-left-content .footer-btn-inner {
    display: inline;
  }
  body .footer-left-content .footer-btn-inner a {
    padding: 7px 0;
  }
  .main-space {
    padding: 0 16px;
  }
  body > footer .footer-link > li {
    padding-bottom: 0px;
    line-height: 20px;
  }
  body > footer .footer-link {
    font-size: 11px;
  }
  .footer-card {
    font-size: 17px;
  }
  body .social-icons-sections {
    gap: 11px;
  }
  .footer-sections .text-align-end,
  .footer-sections .text-right {
    text-align: start;
  }
}
.friends-image {
  height: 280px !important;
  border: 1px solid #5dbae6;
  border-radius: 24px;
}
.btn-green-contact {
  width: 210px;
  background-color: var(--radium-orange-600);
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
.why-footprint-component-sections {
  padding-top: 76px;
}
.why-footprint-component-sections .why-footprint-component {
  padding-bottom: 76px;
}
.why-footprint-component-sections
  .why-footprint-component
  .pace-mind-sections-title {
  font-size: 59px;
  font-weight: 700;
  line-height: 94px;
  max-width: 493px;
  color: var(--ency-400);
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}
.why-footprint-component-sections .why-footprint-component .text-orange-600 {
  color: var(--radium-orange-600);
}
.why-footprint-component-sections .why-footprint-component .text-sky-blue {
  color: var(--blue-color-400);
}
.why-footprint-component-sections .why-footprint-component .place-carry-copy {
  color: var(--black-600);
  font-size: 23px;
  font-weight: 400;
  line-height: 37px;
  max-width: 874px;
}
.why-footprint-component-sections .why-footprint-component .btn-resources {
  margin: 30px 0;
}
.why-footprint-component-sections .second-section .over-story-title {
  color: var(--blue-color-800);
  font-size: 59px;
  font-weight: 800;
  line-height: 94px;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}
.why-footprint-component-sections .second-section .over-story-title span {
  color: var(--blue-color-400);
}
.why-footprint-component-sections .second-section .over-story-img {
  border-radius: 15px;
  margin-bottom: 10px;
}
.why-footprint-component-sections .second-section .over-stroy-digress,
.why-footprint-component-sections .second-section .over-story-username {
  color: var(--black-600);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
}
.why-footprint-component-sections .second-section .over-stroy-description {
  color: var(--black-600);
  font-size: 20px;
  font-weight: 400;
  line-height: 37px;
  max-width: 1100px;
}
.why-footprint-component-sections .second-section .over-story-inner {
  padding: 16px 0;
}
.why-footprint-component-sections .third-sections .third-sections-title {
  color: var(--blue-color-800);
  font-size: 59px;
  font-weight: 800;
  line-height: 94px;
  text-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px;
}
.why-footprint-component-sections .third-sections .third-sections-title span {
  color: var(--blue-color-400);
}
.why-footprint-component-sections .third-sections .third-inner-sections {
  color: var(--black-600);
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  line-height: 37px;
  max-width: 1096px;
  margin: 39px auto;
}
.why-footprint-component-sections .four-sections .four-sections-title {
  color: var(--blue-color-800);
  font-size: 59px;
  font-weight: 800;
  line-height: 94px;
  text-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px;
}
.why-footprint-component-sections .four-sections .four-sections-title span {
  color: var(--blue-color-400);
}
.why-footprint-component-sections .four-sections .four-section-inner-block {
  padding: 50px;
}
.why-footprint-component-sections
  .four-sections
  .four-section-inner-block
  .four-inner-title {
  color: var(--black-600);
  font-size: 23px;
  font-weight: 500;
  line-height: 37px;
}
.why-footprint-component-sections
  .four-sections
  .four-section-inner-block
  .how-work-makers {
  margin-left: 11px;
  color: var(--black-600);
}
.why-footprint-component-sections
  .four-sections
  .four-section-inner-block
  .how-work-makers
  li {
  list-style: disc !important;
  font-size: 18px;
  font-weight: 400;
  font-family: Omnes, sans-serif;
}
.why-footprint-component-sections
  .four-sections
  .four-section-inner-block
  .how-work-makers
  li::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
.why-footprint-component-sections .four-sections .how-we-work-card {
  background: var(--blue-color-400);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 80px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: auto;
  justify-items: center;
  max-width: 100%;
  padding: 30px 50px;
  text-align: center;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  width: auto;
  cursor: pointer;
}
.why-footprint-component-sections .four-sections .how-we-work-card:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 20px 10px 50px rgba(0, 75, 117, 0.3);
}
.why-footprint-component-sections .four-sections .how-work-work-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(379px, 1fr));
  gap: 54px;
  margin: 0 76px;
  gap: 30px;
  margin-bottom: 30px;
}
.why-footprint-component-sections .four-sections .how-we-work-label {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0;
}
.why-footprint-component-sections .four-sections .how-work-img {
  height: auto;
  max-width: 70%;
  object-fit: cover;
  width: auto;
  margin-top: 11px;
}
.why-footprint-component-sections .four-sections .work-style {
  text-align: center;
  background: #fff;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  color: var(--blue-color-400);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0 90px;
  padding: 10px;
}
.why-footprint-component-sections .five-sections {
  position: relative;
}
.why-footprint-component-sections .five-sections .five-sections-title {
  color: var(--blue-color-800);
  font-size: 59px;
  font-weight: 800;
  line-height: 94px;
  text-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px;
}
.why-footprint-component-sections .five-sections .five-sections-title span {
  color: var(--blue-color-400);
}
.why-footprint-component-sections .five-sections .description-block {
  color: var(--black-600);
  font-size: 23px;
  font-weight: 500;
  line-height: 37px;
  max-width: 1096px;
  margin: auto;
  text-align-last: center;
}
.why-footprint-component-sections .five-sections .five-section-block {
  position: relative;
  padding: 75px 0;
}
.why-footprint-component-sections
  .five-sections
  .five-section-block
  .img-team-details {
  border-radius: 21px;
  margin-bottom: 14px;
  cursor: pointer;
}
.why-footprint-component-sections
  .five-sections
  .five-section-block
  .img-team-details:hover {
  box-shadow: -20px 20px 10px rgba(0, 75, 117, 0.15);
  object-fit: cover;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.why-footprint-component-sections
  .five-sections
  .five-section-block
  .tame-data-details {
  font-size: 17px;
  font-weight: 300;
  color: var(--black-600);
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  border-radius: 60px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.why-footprint-component-sections .six-sections .need-us-title {
  color: var(--blue-color-800);
  font-size: 59px;
  font-weight: 800;
  line-height: 51px;
  text-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px;
}
.why-footprint-component-sections .six-sections .need-us-title p {
  margin-bottom: 22px;
  padding: 0;
}
.why-footprint-component-sections
  .six-sections
  .need-us-title
  .text-skyblue-600 {
  color: var(--blue-color-400);
}

@media (max-width: 1400px) {
  .why-footprint-component-sections {
    padding: 30px 0;
  }
  .why-footprint-component-sections .why-footprint-component {
    padding-bottom: 30px;
  }
  .why-footprint-component-sections
    .why-footprint-component
    .pace-mind-sections-title {
    font-size: 46px;
    line-height: 59px;
  }
  .why-footprint-component-sections .four-sections .four-section-inner-block {
    padding: 30px;
  }
  .why-footprint-component-sections
    .four-sections
    .four-sections
    .four-sections-title {
    font-size: 50px;
    line-height: 60px;
  }
  .why-footprint-component-sections .four-sections .how-we-work-card {
    padding: 22px;
  }
  .why-footprint-component-sections .four-sections .how-we-work-label {
    font-size: 19px;
    line-height: 37px;
  }
  .why-footprint-component-sections .four-sections .how-work-work-data {
    gap: 28px;
    margin: 0 auto;
  }
  .why-footprint-component-sections
    .five-sections
    .five-section-block
    .tame-data-details {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 1200px) {
  .why-footprint-component-sections .four-sections .four-section-inner-block {
    padding: 10px;
  }
  .why-footprint-component-sections
    .four-sections
    .four-sections
    .four-sections-title {
    font-size: 41px;
    line-height: 58px;
  }
  .why-footprint-component-sections .four-sections .four-inner-title {
    font-size: 19px;
    line-height: 21px;
  }
  .why-footprint-component-sections .four-sections .how-we-work-card {
    border-radius: 17px;
    padding: 18px;
  }
  .why-footprint-component-sections
    .four-sections
    .how-we-work-card
    .how-work-img {
    max-width: 43%;
  }
  .why-footprint-component-sections .four-sections .how-work-work-data {
    grid-template-columns: repeat(auto-fit, minmax(331px, 1fr));
  }
}

@media (max-width: 767.98px) {
  .why-footprint-component-sections {
    padding: 5px 0;
  }
  .why-footprint-component-sections .why-footprint-component {
    padding-bottom: 5px;
  }
  .why-footprint-component-sections
    .why-footprint-component
    .pace-mind-sections-title {
    font-size: 40px;
    line-height: 51px;
  }
  .why-footprint-component-sections .why-footprint-component .btn-resources {
    display: flex;
    justify-content: center;
    margin: 12px 0;
  }
  .why-footprint-component-sections
    .why-footprint-component
    .btn-resources
    button {
    width: -webkit-fill-available;
    padding: 15px;
  }
  .why-footprint-component-sections .why-footprint-component .place-carry-copy {
    text-align: justify;
    font-size: 21px;
    line-height: 28px;
  }
  .why-footprint-component-sections .third-sections .third-sections-title {
    font-size: 41px;
    line-height: 1;
    margin: 0;
  }
  .why-footprint-component-sections .third-sections .third-inner-sections {
    font-size: 19px;
    line-height: 33px;
    margin: 12px auto;
    text-align: justify;
  }
  .why-footprint-component-sections .four-sections .four-sections-title {
    font-size: 32px;
    line-height: 42px;
  }
  .why-footprint-component-sections
    .four-sections
    .four-section-inner-block
    .four-inner-title {
    font-size: 21px;
    line-height: 37px;
    text-align: justify;
  }
  .why-footprint-component-sections .five-sections .five-section-block {
    padding: 18px 0;
  }
  .why-footprint-component-sections .five-sections .five-sections-title {
    font-size: 46px;
    line-height: 59px;
  }
  .why-footprint-component-sections .five-sections .description-block {
    text-align: justify;
    font-size: 17px;
    line-height: 30px;
  }
  .why-footprint-component-sections .six-sections .need-us-title {
    font-size: 36px;
    line-height: normal;
  }
}

td {
  font-family: verdana, sans-serif;
  font-weight: normal;
  font-size: 12px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.btn-header {
  /* border: 1px solid #ccc;
  color: #333;
  width: max-content;
  border-radius: 5px;
  padding: 8px 10px;
  margin: 20px 40px 0px 0px; */

  border: none;
  color: #333;
  width: max-content;
  border-radius: 5px;
  padding: 0;
  margin: 0px 0px 0px 20px;
}

.btn-member {
  background-color: #3071a9 !important;
  margin-bottom: 0px !important;
}
.btn-member:focus,
.btn-member:active,
.btn-member:hover {
  transform: translateY(0px) !important;
}
.meber-menu-section .dropdown-menu {
  left: auto !important;
}

.organization-img-section .slick-track .image-section {
  padding: 0 10px;
}
.organization-img-section .slick-track,
.organization-img-section .slick-track .image-section {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.family-history-note p {
  font-size: 13px;
  color: var(--radium-info-600);
  font-weight: 600;
}

.contained-button-file input {
  background: #fff;
  border: 1px solid var(--radium-info-600);
  border-radius: 6px;
  padding: 3px 5px !important;
  max-width: 260px;
}
.contained-button-file input[type="file"]::-webkit-file-upload-button {
  background-color: var(--radium-info-600);
  border-radius: 10px;
  border: 0;
  padding: 5px 10px;
  color: #fff;
}

.switch-text {
  display: inline-block;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
}

.header-profile-container {
  color: var(--radium-info-600) !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 5px;
  /* margin-left: 21px; */
  transition: all 0.5s ease-out 0s;
}

.header-profile-container:hover,
.dropdown-toggle .active {
  background-color: var(--blue-color-400) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
  border-radius: 23px;
  padding: 5px 21px;
}

.privacy-policy-heading {
  color: #283646;
  font-size: 41px;
  font-weight: 600;
  line-height: 63.984px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
}

.privacy-description {
  color: #808080 !important;
  font-size: 16px !important;
  line-height: 29px;
  text-align: justify !important;
}
@media (max-width: 530px) {
  .btn-deep-orange{
    padding: 13px 30px;
    font-size: 18px;
    font-weight: 600;
  }
  .privacy-policy-heading {
    font-size: 32px;
    font-weight: 500;
    line-height: 17.984px;
    margin: 19px 0;
  }
  .why-footprint-component-sections
    .why-footprint-component
    .pace-mind-sections-title {
    line-height: 45px;
    font-size: 36px;
  }
  .why-footprint-component-sections .why-footprint-component .place-carry-copy {
    font-size: 20px;
    line-height: 25px;
  }
  .why-footprint-component-sections .third-sections .third-inner-sections {
    font-size: 18px;
    line-height: 28px;
  }
  .why-footprint-component-sections
    .four-sections
    .four-section-inner-block
    .four-inner-title {
    font-size: 21px;
    line-height: 28px;
  }
  .why-footprint-component-sections .third-sections .third-sections-title {
    font-size: 32px;
  }
  .why-footprint-component-sections .four-sections .four-sections-title {
    font-size: 29px;
    margin: 0;
  }
  .why-footprint-component-sections .four-sections .four-section-inner-block {
    padding: 0;
  }
  .why-footprint-component-sections .four-sections .how-work-work-data {
    grid-template-columns: repeat(auto-fit, minmax(289px, 1fr));
  }
}