.container-success {
  display: grid;
  justify-content: center;
}
.container-success form {
  display: grid;
  justify-content: center;
  margin: 220px 0px 80px 0px;
  padding: 0px 45px;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 30px;
  flex-direction: column;
  /* box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
    rgba(36, 160, 218, 0.333) 0px 15px 30px; */
  border: 1px solid #249fda;
}
.successImage {
  display: grid;
  position: relative;
}
.successImage img {
  margin-left: 55px;
  margin-bottom: 20px;
}

.container-success h2 {
  color: rgba(255, 133, 51, 1);
  text-align: Center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  width: 300px;
}

.container-success p {
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: rgb(218, 31, 31);
}

@media screen and (max-width: 500px) {
  .container-success {
    display: block;
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .container-success h1 {
    margin-top: 1px;
    font-size: 25px;
  }

  .container-success p {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .container-success {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }

  .container-success h1 {
    text-align: center;
    font-size: 25px;
  }
}
