.container {
  display: grid;
  justify-content: center;
}
.container > form {
  /* display: grid;
  justify-content: center;
  margin: 195px 0px 80px 0px;
  padding: 0px 45px;
  border: 0.5px solid;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 50px;
  flex-direction: column;
  box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
    rgba(36, 160, 218, 0.333) 0px 15px 30px; 
   border: 1px solid #249FDA; */
  border-radius: 0px 60px;
  border: 3px dashed var(--radium-orange-600);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 50px 100px 0 rgba(0, 75, 117, 0.3);
  backdrop-filter: blur(20px);
  padding: 37px 37px;
  text-align: center;
  margin-top: 20px;
}
.wel-come-title {
  color: #2c3c4d;
  text-align: center;
  font-size: 64px;
  font-weight: 500;
  padding-top: 50px;
  padding-bottom: 10px;
}
.dropdown-menu-item {
  right: 2% !important;
  left: auto !important;
}
.userIcon {
  position: relative;
}
.uImage {
  height: 20px;

  top: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  padding-left: 14px;
}
.emergency-login {
  width: 680px !important;
}
.loginField {
  display: grid;
  position: relative;
  margin-top: 5px;
  /* text-align: center; */
  width: 380px;
}
.loginField input {
  display: block;
  width: 98%;
  padding: 14px 0 14px 43px;
  margin: 5px 0;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: rgba(237, 239, 241, 0.932);
  border-color: #3ca899 !important;
}
.signup-btn {
  display: flex;
}
.email-btn {
  width: inherit;
}
button {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 5px auto;
  border-radius: 30px;
  border-style: none;
  cursor: pointer;
}
button a {
  text-decoration: none;
  color: rgb(253, 253, 253);
  font-size: 20px;
}
/* .uname {
  height: 20px;
  width: 21px;
  margin-top: 2px;
  padding-top: 35px;
  padding-left: 14px;
} */
/* .pswd {
  height: 20px;
  width: 23px;
  display: flex;
  padding: 2px 2px;
  padding-top: 37px;
  padding-left: 14px;
} */
.container h1 {
  color: var(--radium-info-600);
  text-align: center;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 23px;
}
.btn1 {
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid var(--text-primary-800);;
  color: var(--text-primary-800);
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 5px auto;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: all 0.2s;
  padding: 12px 17px;
  align-items: center;
  margin-bottom: 15px;
}
.btn1:hover {
  background-color: var(--text-primary-800);;
  border: 1px solid #ffffff;
  color: #fff;
  transition: all 0.2s;
}
.btn12 {
  background-color: var(--text-primary-800);
  border-radius: 30px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin: 5px auto;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
  transition: all 0.2s;
  padding: 12px 17px;
  align-items: center;
}
.btn12:hover {
  background-color:  var(--text-primary-800);
  transition: all 0.2s;
}
#btn2 {
  background-color: rgba(13, 94, 132, 1);
  width: 330px;
}
#btn2:hover {
  transform: translateY(-3px);
  background-color: rgb(6, 61, 86);
  transition: all 0.2s;
}
#account {
  color: #000;
  text-align: center;
  font-size: 15px;
  padding-right: 20px;
  text-decoration: none;
}
#l4 {
  /* padding: 40px 0 25px 0; */
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.container p {
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: rgb(218, 31, 31);
}
.loginField img {
  position: absolute;
}
.loginField label {
  justify-self: left;
  font-weight: 500;
  padding-left: -10px;
}
.middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  margin: 5px 0 15px 0;
}
.right label a {
  color: #000;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .container {
    display: block;
    float: none;
    padding: 1px 10px !important;
    margin: 0 !important;
  }
  .container > form {
    padding: 1px 15px 20px !important;
  }

  .container h1 {
    margin-top: 1px;
    font-size: 25px;
  }
  .loginField {
    display: block;
    margin-top: 1px;
    width: 100%;
    text-align: start;
  }
  .loginField input {
    width: 100%;
  }
  .middle {
    font-size: small;
    justify-content: center;
    display: grid;
    /* margin-top: -10px; */
    gap: 5px;
    margin: 5px 0px;
  }
  .loginField img {
    position: absolute;
    /* padding-top: 38px;
    padding-left: 68px; */
    /* width: 18px;
    height: 18px; */
  }

  .right label a {
    justify-content: end;
  }
  button a {
    font-size: small;
  }
  .btn1 {
    width: 195px;
  }
  .btn12 {
    width: 100%;
  }

  #l4 {
    font-size: small;
  }
  .container p {
    text-align: center;
  }
  .wel-come-title {
    font-size: 40px;
    line-height: 40px;
  }
  .emergency-login {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .emergency-login {
    width: 100% !important;
  }
}
@media screen and (max-width: 320px) {
  .container {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .loginField {
    /* justify-content: center; */
  }
  .loginField input {
    /* width: 220px; */
  }
  .middle {
    font-size: small;
    justify-content: center;
    display: grid;
  }
  .loginField img {
    position: absolute;
    /* padding-top: 38px;
    padding-left: 92px; */
    /* width: 18px;
    height: 18px; */
  }

  .right label a {
    justify-content: end;
  }
  button a {
    font-size: small;
  }
  .btn1 {
    width: 180px;
  }
  .btn12 {
    width: 100%;
  }
  #l4 {
    font-size: small;
  }
  .container h1 {
    text-align: center;
    font-size: 25px;
  }
}

@media (min-width: 2300px) and (min-height: 1100px) {
  .main {
    height: calc(100vh - 323px);
  }
}
