
@import url('https://fonts.cdnfonts.com/css/omnes');

body{
    font-style: normal;
    margin: 0;
    font-family: 'Omnes', sans-serif;
}
.StepWrapperw{
    margin: 0px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
  }
  @media (max-width: 900px){
    .StepWrapperw{
        grid-template-columns: repeat(2, auto);
        align-items: center;
        justify-items: center;
    }
  }
  @media (max-width: 450px){
    .StepWrapperw{
      margin: 0px;
    }
}
@media (max-width: 768px){
    .StepWrapperw{
        grid-template-columns: repeat(1, auto);
        align-items: center;
        justify-items: center;
    }
}

