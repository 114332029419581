/* Buttons */
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 50px;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 auto;
  background-color: #d9d9d9;
  margin: 0 3px;
}
.slideshowDot.active {
  background-color: var(--radium-orange-600);
  height: 20px;
  width: 80px;
}
