.scroll {
    overflow-y: auto;
    height: 45vh !important;
    width: 52vw;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.scroll3 {

}
.scroll2 {
    overflow-y: auto;
    max-height: calc(100vh - 290px);
    height: auto;
    width: 52vw;
    /* margin: 10px 5px; 
    padding: 5px; */
    text-align: justify;
}
.scrolluser {
    overflow-y: scroll;
    height: 57vh !important;
    width: 61.5vw !important;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.scrolluserEdit {
    overflow-y: scroll;
    height: 57vh !important;
    width: 59.5vw !important;
    margin: 10px 5px; 
    padding: 5px;
    text-align: justify;
}
.no-scroll {
    height: 50vh;
    width: 52vw;
    margin: 10px 5px;
    padding: 5px;
    text-align: justify;
  }
  
.scroll::-webkit-scrollbar {
    width:7px;
}
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);
}
.scroll3::-webkit-scrollbar {
    width:7px;
    
}
.scroll3::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scroll3::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);
}

.scroll2::-webkit-scrollbar {
    width:7px;
}
.scroll2::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scroll2::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);
}

.scrolluser::-webkit-scrollbar {
    width:7px;
}
.scrolluser::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scrolluser::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);

}
.scrolluserEdit::-webkit-scrollbar {
    width:7px;
}
.scrolluserEdit::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.scrolluserEdit::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);
}
button{
    background-color: #1c93ed;
}

.tableScroll {
    overflow-y: auto;
    max-height: calc(100vh - 186px) !important;
    height: auto;
    /* width: 52vw; */
    /* margin: 10px 5px; 
    padding: 5px; */
    text-align: justify;
    border: 1px solid var(--radium-info-600);
    border-radius: 10px;
    box-shadow: 0px 50px 100px 0px rgba(0, 75, 117, 0.30);
    backdrop-filter: blur(20px);
}

.tableScroll::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
.tableScroll::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius:5px;
}
.tableScroll::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 6px #ffffff; 
    background-color: var(--radium-info-600);
}

@media screen and (max-width: 890px) {
    .tableScroll {
        max-height: calc(100vh - 350px) !important;
    }

    .scroll2 {
        max-height: calc(100vh - 377px);
    }
}

@media screen and (max-width: 400px) {
    .tableScroll {
        max-height: calc(100vh - 236px) !important;
    }
}